/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id$
 */


import	axios					from	'axios' ;


/* ----- set base URL ----- */
axios.defaults.baseURL	= process.env.REACT_APP_FASTAPI_URL ?? '/api/' ;
console.log( process.env.REACT_APP_FASTAPI_URL ) ;
console.log( 'process.env.REACT_APP_FASTAPI_URL=' + process.env.REACT_APP_FASTAPI_URL ) ;


export	function	axiosSetBearerToken( token ) {
	if( token )
		axios.defaults.headers.common['Authorization']	= 'bearer ' + token ;
}


/* ----- 共通レスポンス処理 ----- */
axios.interceptors.response.use(

	/* ----- 成功時は何もせずに response を返すだけ ----- */
	(response)	=> response,

	/* ----- エラーハンドリング ----- */
	(error)		=> {
		console.log( "failed to access: ", error ) ;

		/* ----- エラーメッセージ取得 ----- */
		const errorMessage	= error.response?.data || 'An unexpected error occurred' ;
        
        // alert dialogを表示
        alert( errorMessage ) ;
        
        return( Promise.reject( error )) ;
    }
) ;

